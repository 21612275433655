<script setup>
import {computed} from "vue";
import {XCircleIcon} from "@heroicons/vue/24/outline/index.js";

const props = defineProps({
    form: {
        type: Object,
        required: true
    }
})

const hasError = computed(() => {
    return Object.keys(props.form.errors).length > 0
});

</script>
<template>
    <form>
        <div v-auto-animate>
            <div
                dusk="form-session-error-modal"
                v-if="hasError"
                class="bg-red-100 dark:bg-red-900 px-3 py-4 rounded-md mb-10"
            >
                <div class="flex">
                    <div class="flex-shrink-0">
                        <XCircleIcon
                            @click.prevent="props.form.clearErrors()"
                            class="size-6 text-red-600 dark:text-red-100 cursor-pointer"
                            aria-hidden="true"
                        />
                    </div>
                    <div class="ml-3">
                        <h2 class="font-medium mb-1">{{ __('The submitted values are not valid. Please see bellow.') }}</h2>
                        <ul class="text-sm">
                            <li v-for="error in form.errors">
                                {{ error }}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="space-y-5">
            <slot/>
        </div>
    </form>
</template>
